  <template>
  <b-row>
    <b-col sm="12">
      <div class="iq-card-header d-flex justify-content-between">
          <div class="iq-header-title">
             <h4 class="card-title mb-2">Notification</h4>
          </div>
      </div>
    </b-col>
    <b-col sm="12">
      <div class="iq-card" v-for="(data,index) in notificationData" :key="index">
        <div class="iq-card-body">
          <ul class="notification-list m-0 p-0">
            <li class="d-flex align-items-center" >
              <div class="user-img img-fluid">
                <img v-if='data.img' :src="data.img" alt="story-img" class="rounded-circle avatar-40">
                <img v-else class="rounded-circle avatar-40" src="../../../assets/images/user-icon.svg"  alt="story-img">
              </div>
              <div class="media-support-info ml-3">
                <h6>{{data.heading}}</h6>
                <p class="mb-0">{{data.time}}</p>
              </div>
              <div class="d-flex align-items-center">
                <div class="iq-card-header-toolbar d-flex align-items-center">
                     <b-dropdown id="dropdownMenuButton40" right variant="none" menu-class="p-0">
                      <template v-slot:button-content>
                        <b-link href="#" class="dropdown-toggle text-primary"><i class="ml-3 ri-more-2-line"></i></b-link>
                      </template>
                      <b-dropdown-item href="#"><i class="ri-delete-bin-6-fill mr-2"></i>Delete</b-dropdown-item>
                     
                    </b-dropdown>
                </div>
              </div>
            </li>
          </ul>
        </div>     

      </div>
    </b-col>
    <!-- <b-col sm="12">
        <div class="mt-3 text-center mt-5">
      <b-pagination :value="1" :total-rows="50" align="center" />
        </div>
    </b-col> -->
  </b-row>
  </template>
<script>
import { socialvue } from '../../../config/pluginInit'
import axios from '../../../components/axios'
export default {
  name: 'Notification',
  
  mounted () {
    socialvue.index()
   var parent = this;
   axios.defaults.headers["Authorization"] = 
    "Bearer " + localStorage.getItem("token");
    const config = {
      method: "get",
      url: "getnotification",
    };

    axios(config).then(res => {
      parent.notificationData = res.data.notifications

    }).catch(err => {
        console.log(err.response);
    })
  },
  data () {
    return {
      seen: true,
      notificationData: [],
      action: [
        {
          icon: 'ri-eye-fill mr-2',
          title: 'View'
        },
        {
          icon: 'ri-delete-bin-6-fill mr-2',
          title: 'Delete'
        },
        {
          icon: 'ri-pencil-fill mr-2',
          title: 'Edit'
        },
        {
          icon: 'ri-printer-fill mr-2',
          title: 'Print'
        },
        {
          icon: 'ri-file-download-fill mr-2',
          title: 'Download'
        }
      ]
    }
  }
}
</script>
